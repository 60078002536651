<!-- 店铺管理 -->
<template>
  <div class="do-page-storemanagement">
    <base-nav-bar
      @click-left="$router.replace('/my')"
      left-arrow
      title="店铺管理"
    >
      <div
        class="right-text"
        @click="
          $router.push({
            path: '/my/store-claim',
            query: {
              type: 'new',
            },
          })
        "
      >
        <span>新增</span>
      </div>
    </base-nav-bar>
    <van-action-sheet
      v-model:show="operation"
      :actions="actions"
      @select="onSelect"
      cancel-text="取消"
    />

    <base-bscroll
      :safeareaNavbar="true"
      @refScroll="refScroll"
      :options="{ pullUpLoad: true, bounce: true }"
    >
      <div class="do-page-storemanagement-content">
        <base-nodata v-if="isNodata" description="暂无店铺数据"></base-nodata>
        <div class="store-card" v-else v-for="item in listData" :key="item.id">
          <!-- @clirefScrollrefScrollck="openOperation(item.id)" -->

          <div class="overlay-wrap">
            <div class="overlay"></div>
            <van-icon
              name="clock"
              class="overlay-icon"
              size="0.5rem"
              color="#ff976a"
            />
            <span>审核中</span>
          </div>
          <div class="store-card-info">
            <img class="logo" v-if="item.live_pic" :src="item.live_pic" />
            <div class="info">
              <div class="title">
                {{ item.shop_name }}
              </div>
              <div class="title-tip">
                {{ item.shop_address }}
              </div>
            </div>
          </div>
          <img class="store-card-map" :src="item.uri" />
        </div>
        <div v-if="listData.length !== 0">
          <div v-if="pullingUpBol" class="loding-more">加载中</div>
          <div v-if="!pullingUpBol" class="loding-more">没有更多了</div>
        </div>
      </div>
    </base-bscroll>
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick } from "vue";
import { Dialog, Toast } from "vant";
import { onBeforeRouteLeave } from "vue-router";
import { getConfirmShops } from "@/axios/user";
const cursor = ref({});
const listData = ref([]);
const pullingUpBol = ref(true);
const isNodata = ref(false);
let scrollRef = null;

function refScroll(scroll) {
  scrollRef = scroll;

  scrollRef.on("pullingUp", () => {
    console.log("pullingUp");
    if (cursor.value) {
      setTimeout(async () => {
        await getDataList();
        scrollRef.finishPullUp();
      }, 500);
    }
  });
}

onMounted(() => {
  getDataList();
});

async function getDataList() {
  try {
    const { code, data } = await getConfirmShops({
      cursor: cursor.value,
    });
    if (code === 0 && data) {
      // 暂无数据
      if (
        data &&
        data.items &&
        data.items.length === 0 &&
        listData.value.length == 0
      ) {
        isNodata.value = true;
      } else {
        listData.value = listData.value.concat(data.items || []);
        cursor.value = data.next;
        if (!cursor.value) {
          pullingUpBol.value = false;
          console.log("pullingUpBol", pullingUpBol);
        }
        nextTick(() => {
          if (scrollRef) scrollRef.refresh();
        });
      }
    }

    console.log("res", data);
  } catch (error) {
    if (error) {
      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}

const operation = ref(false);
function openOperation(id) {
  operation.value = true;
}

const actions = [
  { name: "店铺信息修改", type: 1 },
  { name: "环境信号采集", type: 2 },
  { name: "删除店铺", type: 3 },
];
const onSelect = ({ type }) => {
  // 默认情况下点击选项时不会自动收起
  // 可以通过 close-on-click-action 属性开启自动收起
  if (type === 1) {
  }
  if (type === 2) {
    Toast("功能暂未开通");
  }
  if (type === 3) {
    Dialog.confirm({
      title: "提示",
      className: "storedelete-dialog",
      message: `确定要删除此店铺？`,
      confirmButtonText: "确定",
    }).then(() => {});
  }
  operation.value = false;
};
</script>

<style lang="scss">
.storedelete-dialog {
  .van-dialog__message {
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
  .van-dialog__cancel {
    color: #0057ff !important;
  }

  .van-dialog__confirm {
    color: #323233;
  }
}
.do-page-storemanagement {
  &-content {
    padding: 0.24rem 0.24rem 0rem 0.24rem;
    padding-bottom: calc(0.24rem + constant(safe-area-inset-bottom));
    padding-bottom: calc(0.24rem + env(safe-area-inset-bottom));
  }

  .overlay-wrap {
    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, 20%);
      color: #ffffff;
      font-size: 0.28rem;
      z-index: 111;
      font-weight: 400;
    }
    img {
      width: 100%;
    }
    .overlay-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -130%);
      z-index: 100;
    }
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.6);
    border-radius: 0.16rem;
    z-index: 100;
  }
  .loding-more {
    text-align: center;
    color: #969799;
  }

  .van-action-sheet__content {
    .van-action-sheet__name {
      font-size: 0.32rem;
      font-weight: 400;
      color: #02040f;
    }
  }
  .van-action-sheet__cancel {
    font-size: 0.32rem;
    font-weight: 400;
    color: rgba(2, 4, 15, 0.45);
  }
  .do-com-scroll-wrapper {
    background: $col_bg;
  }
  .right-text {
    font-size: 0.28rem;
    font-weight: 400;
    color: #0057ff !important;
    transition: all 0.3s;
  }

  .store-card {
    background: #ffffff;
    border-radius: 0.16rem;
    padding: 0.24rem 0.16rem 0.16rem 0.16rem;
    margin-bottom: 0.24rem;
    position: relative;

    &:last-child {
      margin-bottom: 0;
    }
    &-info {
      display: flex;
      align-items: center;
      .logo {
        margin-left: 0.08rem;
        width: 0.72rem;
        height: 0.72rem;
        border-radius: 50%;
        margin-right: 0.16rem;
      }

      .info {
        display: flex;
        flex-direction: column;
        .title {
          font-size: 0.32rem;
          font-weight: 500;
          color: rgba(2, 4, 15, 0.85);
        }

        .title-tip {
          font-size: 0.24rem;
          font-weight: 400;
          color: rgba(2, 4, 15, 0.45);
        }
      }
    }

    &-map {
      margin-top: 0.24rem;
      height: 2.56rem;
      border-radius: 0.12rem;
    }
  }
}
</style>
